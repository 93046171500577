<template>
	<div>
		<H5header :type="2"></H5header>
		<div class="theleftside">
			<div>
				<div class="title top">
					<img src="../../assets/image/title1.png" alt="">
					<p>公示信息</p>
				</div>
				<div class="box">
					<div style="width: 100%;height: 17PX;"></div>
					<div class="box-back">
						<img :src="date.avatar" alt=""
							class="box-logo">
						<div class="box-title">

							<div class="box-name">姓名：{{date.refereeName}}</div>
							<div class="box-name">性别：{{date.refereeSex == 1 ? '男':(date.refereeSex == 2 ? '女':'')}}</div>
							<div class="box-name">级别：{{date.certificateList ?date.certificateList.referee_level : ''}}</div>
							<div class="box-name">项目：{{date.certificateList ?date.certificateList.sportName : ''}}</div>
							<div class="box-name"  @click="getcertificate(date.certificateList.certificate_unit)">发证单位：<span  style="color: #2A7AFF">{{date.certificateList ?date.certificateList.certificate_unit : ''}}</span></div>
							<div class="box-name">注册所在区：{{date.certificateList ?date.certificateList.certificate_area : ''}}</div>
						</div>
					</div>
					<div style="width: 100%;height: 15PX;"></div>
					<!-- <div class="ststus">审核状态：2023年年审通过</div> -->
				</div>
			</div>

			<div style="margin-top: 19PX;">
				<div class="title">
					<img src="../../assets/image/title2.png" alt="">
					<p>执裁记录</p>
				</div>
				<div class="box" style="height: auto;">
					<div class="timeline">
						<el-timeline :reverse="reverse">
							<el-timeline-item v-for="(item, index) in date.enforcementList" :key="index"
									:timestamp="item.match_date" color="#0063FF">
									{{item.match_name}}
								</el-timeline-item>
						</el-timeline>
					</div>
				</div>
			</div>
			<div style="margin-top: 19PX;">
				<div class="title">
					<img src="../../assets/image/title2.png" alt="">
					<p>晋升记录</p>
				</div>
				<div class="box" style="height: auto;">
					<div class="timeline">
						<el-timeline :reverse="reverse">
							<el-timeline-item v-for="(item, index) in date.promotionList" :key="index" :timestamp="item.certificateDate | format" color="#0063FF">{{item.sportsName}}&nbsp;&nbsp;{{item.refereeLevel}}证书&nbsp;&nbsp;{{ item.certificateUnit }}</el-timeline-item>
						</el-timeline>
					</div>
				</div>
			</div>
			<!-- <div>
					<div class="title">
						<img src="../../assets/image/title3.png" alt="">
						<p>相关报道</p>
					</div>
					<div class="box" style="height: auto;">
						<div class="none">暂无相关报道信息</div>
					</div>
				</div> -->
		</div>
		<div class="therightside">
			<div>
				<div class="title">
					<img src="../../assets/image/title4.png" alt="">
					<p>相关裁判</p>
				</div>
				<div class="box" style="height: auto;">
					<div class="berelatedto-box">
						<div class="berelatedto" v-for="(item,index) in refereeList" :key="index" @click="query(item)">
							<img :src="item.image"
								alt="" class="berelatedto-logo">
							<div class="berelatedto-name">{{item.refereeName}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import H5header from "./components/header2.vue"
	export default {
		name: '',
		components: {
			H5header
		},
		data() {
			return {
				reverse: false,
				date: {
					certificateList:{},
					enforcementList:[]
				},
				info:{
					pageNo: 1,
					pageSize: 12,
					refereeLevel: '',
					certificateArea: '',
					sports: '',
					refereeName: '',
					refereeSex: '',
					applyForStatus: '',
					startDate: '',
					endDate: '',
				},
				refereeList: [],
				options2:[],
				options3:[],
				id:''
			}
		},
		filters: {
			format(x) {
				if (x != null && x != '') {
					let time = x.split(" ")[0]
					let parts = time.split('-');
					let result = parts[0] + '-' + parts[1];
					return result
				}
			}
		},
		created() {
			this.judgmentLevel()
			this.areaList()
			this.info.sports = this.$route.query.sports
			this.info.notInIds = this.$route.query.id
			setTimeout(()=>{
				this.id = this.$route.query.id
			},1000)
			this.getRefereeDetails(this.$route.query.id)
			this.getRefereeBase()
		},
		methods: {
			judgmentLevel(){
				this.$api.judgmentLevel().then(res => {
					this.options2 = res.data.data.result
				})
			},
			areaList() {
				this.$api.areaList().then(res => {
					this.options3 = res.data.data.result
				})
			},
			getRefereeDetails(e) {
				this.$api.getRefereeDetails({refereeId:e}).then(res => {
					if(res.data.data.result.avatar){
						this.$api.getFile({objectName: res.data.data.result.avatar}).then(ess=>{
							this.$convert(ess.data.data.result.url).then((fileUrl) => {
								res.data.data.result.avatar = fileUrl
								this.$forceUpdate()
							})
						})
					}else{
						res.data.data.result.avatar = require('@/assets/image/defaults.png')
						this.$forceUpdate()
					}
					if(res.data.data.result.certificateList && res.data.data.result.certificateList.length > 0){
						res.data.data.result.certificateList = res.data.data.result.certificateList[0]
						for(let i in this.options2){
							if(res.data.data.result.certificateList.referee_level == this.options2[i].itemValue){
								res.data.data.result.certificateList.referee_level = this.options2[i].itemText
							}
						}
						for(let i in this.options3){
							if(res.data.data.result.certificateList.certificate_area == this.options3[i].itemValue){
								res.data.data.result.certificateList.certificate_area = this.options3[i].itemText
							}
						}
					}

					res.data.data.result.enforcementList.forEach(item=>{
						let match_date = JSON.parse(item.match_date)
						let arr = match_date.map(item=>{return item.startDate + ' 至 ' + item.endDate})
						item.match_date = arr.join("，")
					})

					res.data.data.result.promotionList.forEach(item=>{
						item.certificateUnit = this.$decrypt(item.certificateUnit  || '')
					})
					res.data.data.result.refereeName = this.$decrypt(res.data.data.result.refereeName  || '')
					res.data.data.result.certificateList.certificate_unit = this.$decrypt(res.data.data.result.certificateList.certificate_unit  || '')
					this.date = res.data.data.result

					let img = 'https://admin.bjcac.org.cn/jeecgboot/jeecg-system/sys/common/staticMinioFile?minFileName=' + res.data.data.result.avatar
					this.$getwxsdk(res.data.data.result.refereeName  + '- 裁判详情 - 北京市体育竞赛管理和国际交流中心',img)
				})
			},
			getRefereeBase(){
				this.$api.getRefereeBase(this.info).then(res=>{
					res.data.data.result.records.forEach(item=>{
						if(item.avatar){
							this.$api.getFile({objectName:item.avatar}).then(ess=>{
								this.$convert(ess.data.data.result.url).then((fileUrl) => {
									item.image = fileUrl
									this.$forceUpdate()
								})
							})
						}else{
							item.image = require('@/assets/image/defaults.png')
							this.$forceUpdate()
						}
					})
					res.data.data.result.records.forEach(item=>{
						item.refereeName = this.$decrypt(item.refereeName)
					})
					this.refereeList = res.data.data.result.records
				})
			},
			query(item){
				this.info.sports = item.sports
				setTimeout(()=>{
					this.id = item.refereeId
				},1000)
				this.info.notInIds = item.refereeId
				this.getRefereeDetails(item.refereeId)
				this.getRefereeBase()
				window.scrollTo(0, 0)
			},
			getcertificate(e) {
				this.$alert(e, '发证单位', {
					confirmButtonText: '关闭',
					callback: action => { }
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	.back {
		width: 1200PX;
		margin: 0 auto;
		display: flex;
		margin-bottom: 100PX;
	}

	.theleftside {
		margin: 0 16PX;
	}

	.title {
		// width: 836PX;
		height: 57PX;
		background: #D8E7FF;
		border-radius: 22PX 22PX 0PX 0PX;
		display: flex;
		align-items: center;
	}

	.top {
		margin-top: 19PX;
	}

	.title img {
		width: 15PX;
		height: 18PX;
		margin-left: 33PX;
	}

	.title p {
		font-size: 15PX;
		font-weight: 500;
		color: #0063FF;
		line-height: 36PX;
		margin-left: 13PX;
	}

	.box {

		background: #F8FBFF;
		border-bottom-left-radius: 22PX;
		border-bottom-right-radius: 22PX;
	}

	.box-back {
		height: 160PX;
		background: url('../../assets/image/coach.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin: 0 7PX;
		display: flex;
		padding-bottom: 15PX;
	}

	.box-logo {
		width: 76PX;
		height: 105PX;
		border-radius: 18PX;
		margin-top: 30PX;
		margin-left: 25PX;
	}

	.box-title {
		margin-left: 21PX;
		margin-top: 15PX;
	}

	.box-name {
		font-size: 12PX;
		font-weight: 400;
		color: #000;
		margin-bottom: 5PX;
		width: 70%;
		white-space: nowrap; /* 确保文本在一行内显示 */
		overflow: hidden; /* 隐藏超出容器的文本 */
		text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
	}

	.ststus {
		font-size: 14PX;
		font-weight: 400;
		color: #0063FF;
		margin-top: 14PX;
		margin-left: 22PX;
		padding-bottom: 15PX;
	}

	.none {
		font-size: 22PX;
		font-weight: 400;
		color: #ACACAC;
		margin-left: 93PX;
		padding: 36PX;
	}

	.timeline {
		padding: 35PX;
	}

	::v-deep .el-timeline-item__tail {
		border-left: 2PX solid #0063FF !important;
	}

	::v-deep .el-timeline-item__content {
		font-size: 15PX;
		font-weight: 400;
		color: #000000;
	}

	::v-deep .el-timeline-item__timestamp {
		font-size: 13PX;
		font-weight: 400;
		color: #ACACAC;
	}

	.therightside {
		margin:16PX 19PX;
	}

	.berelatedto-box {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-top: 18PX;
		margin: 0 11PX;
	}

	.berelatedto {
		margin-bottom: 27PX;
		// margin-right: 25PX;
	}

	.berelatedto:nth-child(2n) {
		margin-right: 0;
	}

	.berelatedto-logo {
		width: 144PX;
		height: 169PX;
		border-radius: 18PX;
	}

	.berelatedto-name {
		font-size: 15PX;
		font-weight: 400;
		color: #000000;
		margin-top: 13PX;
		width: 144PX;
		text-align: center;
	}
	::v-deep .el-timeline-item__timestamp{
		line-height: 20PX;
	}
</style>
